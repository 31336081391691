export const strict = false;
import { deserialize } from 'jsonapi-deserializer';

export const state = () => ({
  paywall: false,
  setAccount: false,
  loading: false,
  ppmStatus: {
    action: false,
    intervalIds: [],
    count: 0,
    ppm_code: 0
  },
  countries: [
    {
      active: true,
      countrycode: 'NL',
      price: '0.90 /min.',
      price_int: '90',
      phonenumber: '0906-729 0019',
      phonenumber_int: '09067290019',
      sub_detail_id: 6,
      payroute: 'ppm/callnow',
      payment_method_id: 5,
    },
    {
      active: false,
      countrycode: 'BE',
      price: '1.00 /min.',
      price_int: '100',
      phonenumber: '0906-60392',
      phonenumber_int: '090660392',
      sub_detail_id: 6,
      payroute: 'ppm/pincode',
      payment_method_id: 5,
    },
  ],
  accessitems: {
    subQuery: {
      return_url: false,
      product_id: 2,
      user_id: false,
      resource_id: false,
      video_id: false,
      ref: '',
    },
    item: false,
    choice: {
      id: 1,
    },
    step: 0,
    account: {
      tab: 'email',
    },
    cta: {
      title: 'Verder',
      target: 'step',
      disabled: false
    },
    options: [
      {
        id: 0,
        published: true,
        title: 'Bellen',
        slug: 'bellen',
        price: '€0,90 p/min',
        icon: 'phone',
        type: 'choice',
        active: false,
        bundle_text: 'Te bekijken met: Bellen',
        subscription: 3,
        active_bundles: 3,
        benefits: [
          {
            title: 'Uniek servicenummer',
            icon: 'check',
            icon_color: '#76cb1a'
          },
          {
            title: 'Zolang je aan de lijn blijft kan je de sexfilm kijken',
            icon: 'check',
            icon_color: '#76cb1a'
          },
          {
            title: 'Op Android houd je \'m op mute. Anders stopt de stream',
            icon: 'exclamation',
            icon_color: '#76cb1a'
          },
          {
            title: 'Verbreek de verbinding als je klaar bent',
            icon: 'triangle-exclamation',
            icon_color: '#f08700'
          }
        ]
      },
      {
        id: 1,
        published: true,
        title: 'Direct',
        slug: 'direct',
        price: '€6,99 p/maand',
        icon: 'bolt',
        type: 'choice',
        active: false,
        subscription: 2,
        bundle_text: 'Maak jouw keuze',
        payroute: 'abo/recurring',
        payment_method_id: 10,
        active_bundles: 2,
        bundles: [
          {
            id: 'B01',
            published: true,
            title: 'Actie: Direct de eerste 3 maanden',
            slug: 'bundle_1',
            price: '€17,50 voor 3mnd',
            price_int: 1750,
            sub_price: '€6,99 p/maand',
            sub_price_int: 699,
            sub_detail_id: 20,
            icon: 'bolt',
            type: 'direct_bundle',
            paywall_text: 'Direct abonnement (doorlopend)',
            sale: true,
            discount: '€17,50 voor 3mnd',
            active: false,
          },
      // {
      //   id: 'B03',
      //   published: true,
      //   title: 'Actie: Eerste 12 mndn',
      //   slug: 'bundle_1',
      //   price: '€49,99 voor 12mnd',
      //   price_int: 4999,
      //   sub_price: '€6,99 p/maand',
      //   sub_price_int: 699,
      //   sub_detail_id: 49,
      //   icon: 'bolt',
      //   type: 'direct_bundle',
      //   paywall_text: 'Direct abonnement (doorlopend)',
      //   sale: 'xxl',
      //   discount: '49,99 voor 12mnd',
      //   active: false,
      // },
          {
            id: 'B02',
            published: true,
            title: 'Direct',
            slug: 'bundle_2',
            price: '€6,99 p/mnd',
            price_int: 699,
            sub_price: '€6,99 p/maand',
            sub_price_int: 699,
            sub_detail_id: 8,
            icon: 'bolt',
            type: 'direct_bundle',
            paywall_text: 'Direct abonnement (doorlopend)',
            sale: false,
            active: false,
          },
      // {
      //   id: 'B04 ',
      //   published: true,
      //   title: '5 jr Direct',
      //   slug: 'bundle_3',
      //   price: '€199,99 voor 5jr',
      //   price_int: 19999,
      //   sub_price: '€6,99 p/maand',
      //   sub_price_int: 699,
      //   sub_detail_id: 52,
      //   icon: 'bolt',
      //   type: 'direct_bundle',
      //   paywall_text: 'Direct abonnement (doorlopend)',
      //   sale: 'xxl',
      //   discount: '199,99 voor 5jr',
      //   active: false,
      // },
        ],
        benefits: [
          {
            title: 'Met dit abonnement stream je onbeperkt',
            icon: 'check',
            icon_color: '#76cb1a'
          },
          {
            title: 'Keuze uit 1.000\'en Nederlandse sexfilms',
            icon: 'check',
            icon_color: '#76cb1a'
          },
          {
            title: '15+ nieuwe sexfilms per maand',
            icon: 'check',
            icon_color: '#76cb1a'
          },
          {
            title: 'Elke maand gratis huurtegoed',
            icon: 'check',
            icon_color: '#76cb1a'
          },
          {
            title: 'Discrete vermelding op je bankafschrift',
            icon: 'check',
            icon_color: '#76cb1a'
          },
          {
            title: 'Elk moment makkelijk online opzegbaar',
            icon: 'check',
            icon_color: '#76cb1a'
          }
        ],
        paymethods: [
          {
            id: 1,
            title: 'iDeal',
            icon: 'ideal.png',
            active: true
          },
        ]
      },
      {
        id: 2,
        published: true,
        title: 'Direct vast',
        slug: 'direct-period',
        price: 'v.a. €10,00 p/maand',
        icon: 'bolt',
        type: 'choice',
        active: false,
        payroute: 'ppt/rent',
        payment_method_id: 10,
        subscription: 1,
        bundle_text: 'Kies jouw vaste Direct-deal',
        active_bundles: 4,
        bundles: [
          {
            id: 'B01',
            published: true,
            title: 'Vluggertje - 1 maand',
            slug: 'bundle_1',
            sub_text: '€0,48 p/dag',
            price: '€14,99',
            price_int: 1499,
            sub_price: '€14,99',
            sub_price_int: 1499,
            sub_detail_id: 16,
            icon: 'bolt',
            type: 'direct_period_bundle',
            paywall_text: 'Vluggertje - 1 maand',
            sale: false,
            active: false,
          },
          {
            id: 'B02',
            published: true,
            title: 'Triootje - 3 maanden',
            slug: 'bundle_2',
            sub_text: '€0,32 p/dag',
            price: '€29,99',
            price_int: 2999,
            sub_price: '€29,99',
            sub_price_int: 2999,
            sub_detail_id: 17,
            icon: 'bolt',
            type: 'direct_period_bundle',
            paywall_text: 'Triootje - 3 maanden',
            sale: false,
            active: false,
          },
          {
            id: 'B03',
            published: true,
            title: 'Ongeremd - 6 maanden',
            slug: 'bundle_3',
            sub_text: '€0,27 p/dag',
            price: '€49,99',
            price_int: 4999,
            sub_price: '€49,99',
            sub_price_int: 4999,
            sub_detail_id: 18,
            icon: 'bolt',
            type: 'direct_period_bundle',
            paywall_text: 'Ongeremd - 6 maanden',
            sale: false,
            active: false,
          },
          {
            id: 'B04',
            published: true,
            title: 'Eindeloos - 1 jaar',
            slug: 'bundle_4',
            sub_text: '€0,20 p/dag',
            price: '€89,99',
            price_int: 8999,
            sub_price: '€89,99',
            sub_price_int: 8999,
            sub_detail_id: 19,
            icon: 'bolt',
            type: 'direct_period_bundle',
            paywall_text: 'Eindeloos - 1 jaar',
            sale: false,
            active: false,
          },
        ],
        paymethods: [
          {
            id: 10,
            title: 'iDeal',
            icon: 'ideal.png',
            active: true
          },
          {
            id: 709,
            title: 'Creditcard',
            icon: 'creditcard.png',
            active: false
          },
          {
            id: 436,
            title: 'Bancontact',
            icon: 'bancontact.png',
            active: false
          }
        ]
      },
      {
        id: 3,
        published: true,
        title: 'Huren',
        slug: 'huren',
        sub_text: '',
        price: 'vanaf € 2,79 / film',
        icon: 'bag-shopping',
        type: 'choice',
        active: false,
        payroute: 'ppt/rentcredits',
        payment_method_id: 10,
        subscription: 6,
        sub_detail_id: 39,
        bundle_text: 'Te bekijken met: Huren',
        active_bundles: 4,
        bundles: [
          {
            id: '1',
            published: true,
            title: 'Enkel deze sexfilm',
            slug: 'bundle_1',
            sub_text: '1 week',
            price: '€4,99',
            price_int: 499,
            sub_price: '€4,99',
            sub_price_int: 499,
            sub_detail_id: 39,
            icon: 'film',
            type: 'rent_bundle',
            paywall_text: 'Huurtegoed 1 film',
            sale: false,
            active: false,
          },
          {
            id: '2',
            published: true,
            title: '5 films',
            slug: 'bundle_2',
            sub_text: '€3,99 p/film',
            price: '€19,99',
            price_int: 1999,
            sub_price: '€19,99',
            sub_price_int: 1999,
            sub_detail_id: 46,
            icon: 'bag-shopping',
            type: 'rent_bundle',
            paywall_text: 'Huurtegoed 5 films',
            sale: false,
            active: false,
          },
          {
            id: '3',
            published: true,
            title: '12 films',
            slug: 'bundle_3',
            sub_text: '€3,33 p/film',
            price: '€39,99',
            price_int: 3999,
            sub_price: '€39,99',
            sub_price_int: 3999,
            sub_detail_id: 47,
            icon: 'bag-shopping',
            type: 'rent_bundle',
            paywall_text: 'Huurtegoed 10 films',
            sale: false,
            active: false,
          },
          {
            id: '4',
            published: true,
            title: '25 films',
            slug: 'bundle_4',
            sub_text: '€2,79 p/film',
            price: '€69,99',
            price_int: 6999,
            sub_price: '69,99',
            sub_price_int: 6999,
            sub_detail_id: 48,
            icon: 'bag-shopping',
            type: 'rent_bundle',
            paywall_text: 'Huurtegoed 25 films',
            sale: false,
            active: false,
          },
      // {
      //   id: '5',
      //   published: true,
      //   title: '50 films',
      //   slug: 'bundle_5',
      //   sub_text: '€1,99 p/film',
      //   price: '€99,99',
      //   price_int: 9999,
      //   sub_price: '99,99',
      //   sub_price_int: 9999,
      //   sub_detail_id: 50,
      //   icon: 'bag-shopping',
      //   type: 'rent_bundle',
      //   paywall_text: 'Huurtegoed 50 films',
      //   sale: 'xxl',
      //   active: false,
      // },
      // {
      //   id: '6',
      //   published: true,
      //   title: 'Gaby en Lorena\'s Trio sex',
      //   slug: 'bundle_6',
      //   sub_text: '1 week',
      //   price: '€0,99',
      //   price_int: 99,
      //   sub_price: '€0,99',
      //   sub_price_int: 99,
      //   sub_detail_id: 51,
      //   icon: 'film',
      //   type: 'rent_bundle',
      //   paywall_text: 'Huurtegoed 1 film',
      //   sale: false,
      //   active: false,
      // },
        ],
        paymethods: [
          {
            id: 10,
            title: 'iDeal',
            icon: 'ideal.png',
            active: true
          },
          {
            id: 709,
            title: 'Creditcard',
            icon: 'creditcard.png',
            active: false
          },
          {
            id: 436,
            title: 'Bancontact',
            icon: 'bancontact.png',
            active: false
          }
        ]
      }
    ],
  },
  accesitem: {
    step: 0,
    choice: false,
  },
  subscriptions: [],
  abonnementen: [],
  creditsbundles: [],
  banks: [],
  choices: {
    access: 'huren',
    paymethod: '',
    account: ''
  },
  subQuery: {
    payroute: 'ppt/rent',
    bank_id: 0,
    payment_method_id: false,
    return_url: false,
    sub_detail_id: 2,
    subscription: 1,
    product_id: 2,
    user_id: false,
    resource_id: false,
    ref: '',
  },
})

export const actions = {   
  async getSubscriptions({commit}) {
    await this.$axios.get(process.env.API_URL + '/subscriptions')
    .then(response => {      
      commit('setSubscriptions', response.data)
    }).catch((error) => {
    });
  },
  subscribe(context) {
    if (!context.state.subQuery.payment_method_id) {
      var notificationObj = [];
      notificationObj.type = 'error';
      notificationObj.message = 'Je hebt geen betaalmethode gekozen.';
      context.commit('notificationStore/setNotification', notificationObj, { root: true });    
      return;
    }
    if (!context.state.subQuery.user_id) {
      var notificationObj = [];
      notificationObj.type = 'error';
      notificationObj.message = 'Je moet inloggen of registreren om te kunnen betalen.';
      context.commit('notificationStore/setNotification', notificationObj, { root: true });    
      return;
    }
    // don't send resource_id if false
    if (!context.state.subQuery.resource_id) {
      delete context.state.subQuery.resource_id;
    }
    this.$axios.post(process.env.API_URL + '/payment/transactions/' + context.state.subQuery.payroute, context.state.subQuery)
    .then(response => {      
      if (response.data.data.attributes.hasOwnProperty('response_url') && response.data.data.attributes.response_url !== 'null') {
        window.location = response.data.data.attributes.response_url;
      }
    }).catch((error) => {
      var notificationObj = [];
      notificationObj.type = 'error';
      notificationObj.message = 'Er is iets mis gegaan met je betaling. Klik op deze melding om contact op te nemen';
      notificationObj.href = '/contact';
      context.commit('notificationStore/setNotification', notificationObj, { root: true });    
    });
  },
  subscribeCredits(context, query) {
    var self = this;
    this.$axios.post(process.env.API_URL + '/users/' + query.user_id + '/credits-start', query)
    .then(response => {      
      if (response.data.user_credit_transaction.id > 0) {
        let cname = 'credit_user_subscription_id';
        let cvalue = response.data.user_credit_transaction.id;
        let exdays = 1/24;
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        var goto = window.location.href.replace('/betalen','?credits=succes');
        window.location = goto;
      }
    }).catch((error) => { 
      console.log(error);     
      var notificationObj = [];
      notificationObj.type = 'error';
      notificationObj.message = 'Er is iets mis gegaan. Je hebt geen toegang';
      context.commit('notificationStore/setNotification', notificationObj, { root: true });    
    });
  },
  subscribePpm(context) {
    this.$axios.post(process.env.API_URL + '/payment/transactions/' + context.state.subQuery.payroute, context.state.subQuery)
    .then(response => {
      if (response.data.data.attributes && response.data.data.attributes.status == "ERROR") {
        var notificationObj = [];
        notificationObj.type = 'error';
        notificationObj.message = 'Je pincode is onjuist.';
        context.commit('notificationStore/setNotification', notificationObj, { root: true });    
      }
      if (response.data.data.attributes && response.data.data.attributes.status == "AUTHORISED") {
        let cname = 'phone_stream_id';
        let cvalue = response.data.data.attributes.phone_stream_id;
        let exdays = 1/24;
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        window.location = context.state.subQuery.return_url + '?phone=succes' 
      }
    }).catch((error) => {
      var notificationObj = [];
      notificationObj.type = 'error';
      notificationObj.message = 'Er is iets mis gegaan met je betaling. Klik op deze melding om contact op te nemen';
      notificationObj.href = '/contact';
      context.commit('notificationStore/setNotification', notificationObj, { root: true });    
    });
  },
  subscribePpmNow(context) {
    this.$axios.post(process.env.API_URL + '/payment/transactions/' + context.state.subQuery.payroute, context.state.subQuery)
    .then(response => {      
      if (response.data.data.attributes && response.data.data.attributes.status == "ERROR") {
        var notificationObj = [];
        notificationObj.type = 'error';
        notificationObj.message = 'Je pincode is onjuist.';
        context.commit('notificationStore/setNotification', notificationObj, { root: true });    
      }
      if (response.data.data.attributes && response.data.data.attributes.status == "AUTHORISED") {
        let cname = 'phone_stream_id';
        let cvalue = response.data.data.attributes.phone_stream_id;
        let exdays = 1/24;
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        window.location = context.state.subQuery.return_url + '?phone=succes' 
      }
    }).catch((error) => {
      var notificationObj = [];
      notificationObj.type = 'error';
      notificationObj.message = 'Er is iets mis gegaan met je betaling. Klik op deze melding om contact op te nemen';
      notificationObj.href = '/contact';
      context.commit('notificationStore/setNotification', notificationObj, { root: true });    
    });
  },
  subscribeAdvents(context, query) {
    var self = this;
    this.$axios.post(process.env.API_URL + '/users/subscription-advent', query)
    .then(response => {
      window.location = '/sexfilms/' + query.slug + '?advent=succes';
    }).catch((error) => { 
      console.log(error);     
      var alertObj = {};
      alertObj.type = 'error';
      alertObj.message = 'Er is iets mis gegaan. Je hebt geen toegang';
      context.commit('notificationStore/setNotification', alertObj, { root: true });    
    });
  },
  async getCountry({commit}) {
    await this.$axios.get(process.env.API_URL + '/user/country')
    .then(response => {  
      if (response.data.countryCode.length) {
        commit('setCountry', response.data.countryCode)
      } else {
        commit('setCountry', 'NL')
      }
    }).catch((error) => {
    });
  },
  checkCoupon({commit, rootState, state}, coupon){
    var dataObj = {
      sub_detail_id: state.accessitems.item.sub_detail_id,
      frontend_id: 1,
      user_id: rootState.auth.user.id,
      coupon: coupon
    };
    this.$axios.post(this.$config.apiUrl + '/payment/transactions/validate', dataObj).then(response => {
      if(response.data.price < state.accessitems.item.price_int) {
        var couponObj = {
          price: response.data.price,
          coupon: coupon
        };
        commit('activateCoupon', couponObj);
      }
    })
  },
  subscribeNew({dispatch, commit, rootState, state}){
    const subQuery = {
      payroute: state.accessitems.choice.payroute,
      payment_method_id: state.accessitems.choice.payment_method_id,
      return_url: state.accessitems.subQuery.return_url,
      sub_detail_id: state.accessitems.item.sub_detail_id,
      subscription: state.accessitems.choice.subscription,
      product_id: state.accessitems.subQuery.product_id,
      user_id: rootState.auth.user.id,
      resource_id: state.accessitems.subQuery.resource_id,
      video_id: state.accessitems.subQuery.video_id,
      ref: '',
      coupon: ''
    }
    if (subQuery.subscription === 6) {
      subQuery.rent_credit_bundle_id = state.accessitems.item.id;
    }
    if (state.accessitems.item.coupon) {
      subQuery.coupon = state.accessitems.item.coupon;
    }
    if (state.accessitems.subQuery.ref) {
      subQuery.ref = state.accessitems.subQuery.ref;
    }
    dispatch('analyticsStore/beginCheckout', subQuery, { root: true });    

    // rent payroute is being used for direct bundles. Rent requires a resource_id. This needs to change.
    if (subQuery.payroute == 'ppt/rent' && !subQuery.resource_id) {
      if (subQuery.sub_detail_id === 16 || subQuery.sub_detail_id === 17 || subQuery.sub_detail_id === 18 || subQuery.sub_detail_id === 19) {
        subQuery.resource_id = 1;
      }
    }
    this.$axios.post(process.env.API_URL + '/payment/transactions/' + subQuery.payroute, subQuery)
    .then(response => {      
      if (response.data.data.attributes.hasOwnProperty('response_url') && response.data.data.attributes.response_url !== 'null') {
        dispatch('analyticsStore/paymentInitiation', subQuery, { root: true });    
        window.location = response.data.data.attributes.response_url;
      } else {
        var alertObj = {
          type: 'error',
          message: 'Er is iets mis gegaan met je betaling. Het lijkt er op dat er een storing is.Ververs de pagina en probeer het nog een keer of klik op deze melding om contact op te nemen',
          href: '/info/contact',
        };
        commit('alertStore/setAlert', alertObj, { root: true });   
      }
    }).catch((error) => {
      var alertObj = {
        type: 'error',
        message: 'Er is iets mis gegaan met je betaling. Het lijkt er op dat er een storing is.Ververs de pagina en probeer het nog een keer of klik op deze melding om contact op te nemen',
        href: '/info/contact',
      };
      commit('alertStore/setAlert', alertObj, { root: true });
    });
  },
  checkAccessPPM({commit, rootState, state}, phone_stream_id){
    const intervalId = setInterval(() => {
      this.$axios.get(process.env.API_URL + '/paid-stream-check/' + phone_stream_id).then(response => {
        if(response && response.data && response.data.status_code) {
          var actionObj = {};
          Object.assign(actionObj, {
            type: 'action',
            data: response.data.action
          });
          commit('setPpmStatus', actionObj)
          var countObj = {};
          Object.assign(countObj, {
            type: 'count',
            data: '++'
          });
          commit('setPpmStatus', countObj);
          if (response.data.status_code == 200) {
            for (let i = 0; i < state.ppmStatus.intervalIds.length; i++) {
              clearInterval(state.ppmStatus.intervalIds[i]);
            }
            commit('videoStore/setPpmAccess', true, { root: true });
          }
        }
      }).catch((error) => {
        console.log(error);
        var alertObj = {};
        alertObj.type = 'error';
        alertObj.message = 'Er is iets mis gegaan. Je hebt geen toegang';
        commit('alertStore/setAlert', alertObj, { root: true });
        commit('resetPpmStatus');
      })
    }, 5000);
    commit('pushIntervalIds', intervalId);
  },
  subscribeWithPPM({commit, rootState, state}){
    const subQuery = {
      payroute: '',
      payment_method_id: state.accessitems.choice.payment_method_id,
      return_url: state.accessitems.subQuery.return_url,
      sub_detail_id: 6,
      subscription: state.accessitems.choice.subscription,
      product_id: state.accessitems.subQuery.product_id,
      resource_id: state.accessitems.subQuery.resource_id,
      video_id: state.accessitems.subQuery.video_id,
      ref: ''
    }
    if(rootState.auth.loggedIn){
      subQuery.user_id = rootState.auth.user.id;
    }
    if (state.accessitems.subQuery.ref) {
      subQuery.ref = state.accessitems.subQuery.ref;
    }
    state.countries.forEach(function(element) { 
      if (element.active) {
        subQuery.payroute = element.payroute;
        subQuery.payment_method_id = element.payment_method_id;
        subQuery.sub_detail_id = element.sub_detail_id;
        subQuery.phonenumber_int = element.phonenumber_int;
      }
    })
    if (subQuery.payroute == 'ppm/pincode' && state.ppmStatus.ppm_code < 1000){
      setTimeout(function() {
        window.location = 'tel:' + subQuery.phonenumber_int;
      }, 3100);
      var actionObj = {};
      Object.assign(actionObj, {
        type: 'action',
        data: 'pincode'
      });
      commit('setPpmStatus', actionObj)
      return;
    }
    if(state.ppmStatus.ppm_code > 999){
      subQuery.payroute = 'ppm/pincode';
    }
    if (state.ppmStatus.ppm_code >= 1000) {
      subQuery.pincode = state.ppmStatus.ppm_code;
    }
    this.$axios.post(process.env.API_URL + '/payment/transactions/' + subQuery.payroute, subQuery)
    .then(response => {
      if (response.data.data.attributes && response.data.data.attributes.status == "ERROR" && subQuery.pincode) {
        var alertObj = {};
        Object.assign(alertObj, {
          type: 'error',
          message: 'Je pincode is onjuist.'
        });
        commit('alertStore/setAlert', alertObj, { root: true });    
      }
      if (response.data.data.attributes && response.data.data.attributes.status == "AUTHORISED") {
        let cname = 'phone_stream_id';
        let cvalue = response.data.data.attributes.phone_stream_id;
        let exdays = 1/24;
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        if (!subQuery.pincode) {
          window.location = 'tel:' + response.data.data.attributes.data.attributes.phone_number;
        }
        // Check PPM
        const intervalId = setInterval(() => {
          this.$axios.get(process.env.API_URL + '/paid-stream-check/' + response.data.data.attributes.phone_stream_id).then(response => {
            if(response && response.data && response.data.status_code) {
              var actionObj = {};
              Object.assign(actionObj, {
                type: 'action',
                data: response.data.action
              });
              commit('setPpmStatus', actionObj)
              var countObj = {};
              Object.assign(countObj, {
                type: 'count',
                data: '++'
              });
              commit('setPpmStatus', countObj);
              if (response.data.status_code == 200) {
                for (let i = 0; i < state.ppmStatus.intervalIds.length; i++) {
                  clearInterval(state.ppmStatus.intervalIds[i]);
                }
                commit('videoStore/setPpmAccess', true, { root: true });
              }
            }
          }).catch((error) => {
            console.log(error);
            var alertObj = {};
            alertObj.type = 'error';
            alertObj.message = 'Er is iets mis gegaan. Je hebt geen toegang';
            commit('alertStore/setAlert', alertObj, { root: true });
            commit('resetPpmStatus');
          })
        }, 5000);
        commit('pushIntervalIds', intervalId);
      }
    })
  },
  getAccessRentCredits({commit, rootState, state}, resource_id){
    commit('setLoading', true);
    const subQuery = {
      subscription: 1,
      sub_detail_id: 2,
      resource_id: resource_id,
      frontend_id: 1
    }
    this.$axios.post(process.env.API_URL + '/users/' + rootState.auth.user.id + '/rent-video', subQuery)
    .then(response => {
      if (response.data) {
        var goto = window.location.pathname + '?rent=success'
        window.location = goto;
        return;
      } else {
        commit('setLoading', false);
        var alertObj = {};
        alertObj.type = 'error';
        alertObj.message = 'Er is iets mis gegaan. Je hebt geen toegang';
        commit('notificationStore/setNotification', alertObj, { root: true });    
      }
    }).catch((error) => {
      commit('setLoading', false);
      var alertObj = {};
      alertObj.type = 'error';
      alertObj.message = 'Er is iets mis gegaan. Je hebt geen toegang';
      commit('notificationStore/setNotification', alertObj, { root: true });    
    });

  },
}

// export const getters = {
//   subscriptions(state) {
//     return state.subscriptions;
//   },
//   abonnementen(state) {
//     return state.abonnementen;
//   },
//   creditsbundles(state) {
//     return state.creditsbundles;
//   },
//   banks(state) {
//     return state.banks;
//   },
//   choices(state) {
//     return state.choices;
//   },
//   subQuery(state) {
//     return state.subQuery;
//   }
// }

export const mutations = {
  setSubscriptions(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;
    var subscr = deserialize(dataObj);
    state.subscriptions = subscr;

    for (var i = 0; i < subscr.length; i++) {
      if (subscr[i].short == 'abo') {
        var abo = subscr[i];    
      }
      if (subscr[i].short == 'ppmc') {
        var ppmc = subscr[i];    
      }
      if (subscr[i].payment_methods[0].banks.length > 0) {
        state.banks = subscr[i].payment_methods[0].banks;
      }
    }
    ppmc.subscription_details.forEach(function(element) { 
      state.creditsbundles.push(element); 
    });
    abo.subscription_details.forEach(function(element) { 
      var price_per_day = element.price / element.period * 60 *24;
      element.price_per_day = Math.round(price_per_day);
      state.abonnementen.push(element); 
    });
  },
  setChoices(state, choice) {
    state.choices[choice.type] = choice.name;
  },
  setSubQuery(state, query) {
    state.subQuery[query.type] = query.id;
  },
  setLoading(state, todo) {
    state.loading = todo;
  },
  setSubQueryTotal(state, query) {
    state.subQuery = query;
  },
  // setAmount(state, query) {
  //   state.subQuery[query.type] = query.price;
  // },
  // resetUserId(state) {
  //   state.subQuery.user_id = false;
  // },
  setRef(state, ref) {
    state.subQuery.ref = ref;
    state.accessitems.subQuery.ref = ref;
  },
  setPaymethod(state, paymethod) {
    state.accessitems.choice.paymethods.forEach(function(method) {
      if (method.id === paymethod.id) {
        method.active = true;
        state.accessitems.choice.payment_method_id = method.id;
      } else {
        method.active = false;
      }
    })
  },
  setAccessItem(state, option) {
    if (option.type == 'direct_bundle') {
      state.accessitems.options[1].bundles.forEach(function(bundle) {
        if (bundle.id === option.id) {
          bundle.active = true;
          state.accessitems.item = bundle;
        } else {
          bundle.active = false;
        }
      })
    } else if (option.type == 'direct_period_bundle') {
      state.accessitems.options[2].bundles.forEach(function(bundle) {
        if (bundle.id === option.id) {
          bundle.active = true;
          state.accessitems.item = bundle;
        } else {
          bundle.active = false;
        }
      })
    } else if (option.type == 'rent_bundle') {
      state.accessitems.options[3].bundles.forEach(function(bundle) {
        if (bundle.id === option.id) {
          bundle.active = true;
          state.accessitems.item = bundle;
          if (bundle.sub_detail_id === 39) {
            state.accessitems.choice.payroute = 'ppt/rent';
          }
        } else {
          bundle.active = false;
        }
      })
    } else {
      state.accessitems.choice = option;
      if (option.slug == 'huren') {
        state.accessitems.item = state.accessitems.options[3].bundles[2];
        state.accessitems.item.active = true;
        state.accessitems.options[3].bundles[0].active = false;
        state.accessitems.options[3].bundles[1].active = false;
        state.accessitems.options[3].bundles[2].active = true;
      }
      if (option.slug == 'direct') {
        state.accessitems.options[1].bundles[0].active = false;
        state.accessitems.item = state.accessitems.options[1].bundles[0];
        state.accessitems.item.active = true;
        state.accessitems.options[1].bundles[0].active = true;
      }
      state.accessitems.cta.title = 'Verder';
      state.accessitems.cta.disabled = false; 
      if (option.slug == 'bellen') {
        state.accessitems.step = 1
        state.accessitems.cta.title = 'Bel en kijk';
        state.accessitems.cta.target = 'ppm';
      }
      state.accessitems.options.forEach(function(item) {
        if (item.id === option.id) {
          item.active = true;
        } else {
          item.active = false;
        }
      })
    }
  },
  setAccountTab(state, tab) {
    state.accessitems.account.tab = tab;
  },
  setAccountTodo(state, todo) {
    state.setAccount = todo;
  },
  setPaywall(state, todo) {
    if (todo && typeof todo === 'string' && todo.includes('direct_xxl')) {
      if (todo.includes('true')) {
        state.accessitems.step = 4;
      } else {
        state.accessitems.step = 3;
      }
      state.accessitems.choice = state.accessitems.options[1];
      state.accessitems.choice.active = true;
      state.accessitems.choice.bundles[0].active = true;
      state.accessitems.item = state.accessitems.choice.bundles[0];
      state.accessitems.subQuery.return_url = 'https://meidenvanholland.nl/account/direct';
      if (state.accessitems.subQuery.resource_id === false) {
        delete state.accessitems.subQuery.resource_id;
      }
    }
    if (todo && typeof todo === 'string' && todo.includes('direct_xxxl')) {
      if (todo.includes('true')) {
        state.accessitems.step = 4;
      } else {
        state.accessitems.step = 3;
      }
      state.accessitems.choice = state.accessitems.options[1];
      state.accessitems.choice.active = true;
      state.accessitems.choice.bundles[2].active = true;
      state.accessitems.item = state.accessitems.choice.bundles[2];
      state.accessitems.subQuery.return_url = 'https://meidenvanholland.nl/account/direct';
      if (state.accessitems.subQuery.resource_id === false) {
        delete state.accessitems.subQuery.resource_id;
      }
    }
    if (todo && typeof todo === 'string' && todo.includes('huren_xxl')) {
      if (todo.includes('true')) {
        state.accessitems.step = 4;
      } else {
        state.accessitems.step = 3;
      }
      state.accessitems.choice = state.accessitems.options[3];
      state.accessitems.choice.active = true;
      state.accessitems.choice.bundles[4].active = true;
      state.accessitems.item = state.accessitems.choice.bundles[4];
      state.accessitems.subQuery.return_url = 'https://meidenvanholland.nl/account/gehuurd';
      if (state.accessitems.subQuery.resource_id === false) {
        delete state.accessitems.subQuery.resource_id;
      }
    }
    if (todo && typeof todo === 'string' && todo.includes('huren_with_coupon')) {
      if (todo.includes('true')) {
        state.accessitems.step = 4;
      } else {
        state.accessitems.step = 3;
      }
      state.accessitems.choice = state.accessitems.options[3];
      state.accessitems.choice.active = true;
      state.accessitems.subQuery.return_url = 'https://meidenvanholland.nl/sexfilms/daphne-neukt-voor-10-deel-1';
      state.accessitems.subQuery.resource_id = 5029;
      state.accessitems.choice.bundles[0].active = true;
      state.accessitems.item = state.accessitems.choice.bundles[0];
    }
    if (todo && typeof todo === 'string' && todo.includes('euroknaller_xxl_')) {
      if (todo.includes('true')) {
        state.accessitems.step = 4;
      } else {
        state.accessitems.step = 3;
      }
      state.accessitems.choice = state.accessitems.options[3];
      state.accessitems.choice.active = true;
      state.accessitems.subQuery.return_url = 'https://meidenvanholland.nl/sexfilms/gaby-en-lorena-bestellen-pik';
      state.accessitems.subQuery.resource_id = 4995;
      state.accessitems.choice.bundles[5].active = true;
      state.accessitems.item = state.accessitems.choice.bundles[5];
    }
    state.paywall = todo;
  },
  setAccessItemStep(state, todo) {
    if (todo) {
      state.accessitems.step ++;
    } else {
      if (state.accessitems.step == 4) {
        state.accessitems.step = 3
      }
      state.accessitems.step --;
      state.accessitems.cta.title = 'Verder';
      state.accessitems.cta.target = 'step';
      state.setAccount = false;
    }
  },
  disableCta(state, todo) {
   state.accessitems.cta.disabled = todo; 
 },
 setCta(state, ctaObj) {
  state.accessitems.cta.title = ctaObj.title;
  state.accessitems.cta.target = ctaObj.target;
  state.accessitems.cta.disabled = ctaObj.disabled; 
},
setAccessItemStepCustom(state, step) {
  state.accessitems.step = step;
},
setCountry(state, code) {
  state.countries.forEach(function(item) {
    if (item.countrycode === code) {
      item.active = true;
    } else {
      item.active = false;
    }
  })
},
activateCoupon(state, couponObj) {
  state.accessitems.item.sale = true;
  let discount = state.accessitems.item.price_int - couponObj.price;
  state.accessitems.item.price_int = couponObj.price;
  state.accessitems.item.discount = '€' +  (discount / 100).toFixed(2);
  state.accessitems.item.price = '€' + (couponObj.price / 100).toFixed(2);
  state.accessitems.item.coupon = couponObj.coupon
},
setResourceData(state, resource) {
  state.accessitems.subQuery.return_url = resource.return_url;
  state.accessitems.subQuery.resource_id = resource.resource_id;
  state.accessitems.subQuery.video_id = resource.video_id;
},
setStoreToRentcredits(state) {
  state.accessitems.choice = state.accessitems.options[3];
  state.accessitems.step = 2;
  state.accessitems.subQuery.return_url = '/account/gehuurd';
  state.accessitems.options[3].bundles[0].title = '1 Film';
  state.accessitems.options[3].bundles[0].sub_text = '€4,99 p/film';
  state.accessitems.options[3].bundles[0].icon = 'bag-shopping'
},
setStoreToOnbeperkt(state) {
  state.accessitems.choice = state.accessitems.options[1];
  state.accessitems.step = 2;
  state.accessitems.subQuery.return_url = 'https://meidenvanholland.nl/account/direct';
  if (state.accessitems.subQuery.resource_id === false) {
    delete state.accessitems.subQuery.resource_id;
  }
},
setStoreToOnbeperktAndRent(state) {
  state.accessitems.choice = state.accessitems.options[1];
  state.accessitems.step = 1;
  if (state.accessitems.subQuery.resource_id === false) {
    delete state.accessitems.subQuery.resource_id;
  }
},
resetPaywall(state) {
  let choice = {
    id: 1,
  }
  state.accessitems.choice = choice;
  state.accessitems.step = 0;
  state.accessitems.subQuery.return_url = false;
},
setPpmCode(state, ppm_code) {
  state.ppmStatus.ppm_code = ppm_code.first + ppm_code.second + ppm_code.third + ppm_code.fourth
},
setPpmStatus(state, statusObj) {
  if(statusObj.type == 'count' && statusObj.data == '++'){
    return state.ppmStatus[statusObj.type] ++;
  }
  state.ppmStatus[statusObj.type] = statusObj.data;
},
resetPpmStatus(state, statusObj) {
  for (let i = 0; i < state.ppmStatus.intervalIds.length; i++) {
    clearInterval(state.ppmStatus.intervalIds[i]);
  }
  state.ppmStatus.action = false;
  state.ppmStatus.interval = false;
  state.ppmStatus.count = 0;
  state.accessitems.cta.disabled = false; 
  document.cookie = "phone_stream_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
},
pushIntervalIds(state, intervalId) {
  state.ppmStatus.intervalIds.push(intervalId);
},
resetIntervalIds(state) {
  for (let i = 0; i < state.ppmStatus.intervalIds.length; i++) {
    clearInterval(state.ppmStatus.intervalIds[i]);
  }
},
// setSubBundlesXXL(state, todo) {
//   if (!todo) {
//     state.accessitems.options[1].bundles[0].published = true;
//     state.accessitems.options[1].bundles[1].published = false;
//     state.accessitems.options[3].bundles[4].published = false;
//     state.accessitems.options[3].active_bundles = 4;
//   } else {
//     state.accessitems.options[1].bundles[0].published = false;
//     state.accessitems.options[1].bundles[1].published = true;
//     state.accessitems.options[3].bundles[4].published = true;
//     state.accessitems.options[3].active_bundles = 5;
//   }
// },
}